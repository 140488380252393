import React, { Component } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
    body: {
    }
})

class Navbar extends Component {

    render() {
        const { classes } = this.props
        return (
            <>
                <div className={classes.body}>
                    <header class="sticky-header main-header sticky-header-elements-headeronly mainmenu-position-menu_in_header">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="header-left">
                                        <a class="logo-link" href="/">
                                            <img style={{ color: "red" }} src="upload/logoBlack.png" alt="goodfellows" class="regular-logo" />
                                            <img src="upload/logoWhite.png" alt="Barrel" class="light-logo" />
                                        </a>

                                        <div class="mobile-main-menu-toggle" data-toggle="collapse" data-target=".collapse"><i class="fa fa-bars"></i></div>
                                    </div>

                                    <div class="header-center">
                                        <div id="navbar" class="navbar navbar-default clearfix mgt-mega-menu menu-right menu-uppercase menu-style-shadow">
                                            <div class="navbar-inner">
                                                <div class="navbar-collapse collapse">
                                                    <ul id="menu-main-menu-restaurant-2" class="nav">
                                                        <li class="mgt-highlight menu-item">
                                                            <a href="https://goodfellows-coffee.square.site/"><span>Order Online</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="header-right">
                                        <ul class="header-nav"></ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </>
        )
    }
}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Navbar)