import React, { Component } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import Home from "./components/Home"


class App extends Component {
  render() {

    return (
      <>
        <Router>
          <Navbar />
          <Route exact path="/" component={() => <Home />} />
          <Footer />
        </Router>
      </>
    )
  }
}
export default App