import React, { Component } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
    body: {
    }
})

class Footer extends Component {

    render() {
        const { classes } = this.props
        return (
            <>
                <div className={classes.body}>
                    <div style={{marginTop:"0px"}} class="container-fluid footer-wrapper">
                        <div class="row">
                            <div class="footer-sidebar-wrapper footer-sidebar-style-dark" data-style="">
                                <div class="footer-sidebar sidebar container footer-sidebar-col-4">
                                    <ul id="footer-sidebar" class="clearfix">
                                        <li id="text-3" class="widget widget_text">
                                            <h2 class="widgettitle">Shop Hours</h2>
                                            <div class="textwidget">
                                                <p>Monday 7:00 am - 4:00 pm </p>
                                                <p>Tuesday 7:00 am - 4:00 pm</p>
                                                <p>Wednesday 7:00 am - 4:00 pm</p>
                                                <p>Thursday 7:00 am - 4:00 pm</p>
                                                <p>Friday 7:00 am - 4:00 pm</p>
                                                <p><span class="text-color-theme">Saturday 7:00 am - 4:00 pm</span></p>
                                                <p><span class="text-color-theme">Sunday 7:00 am - 4:00 pm</span></p>
                                            </div>
                                        </li>
                                        <li id="nav_menu-2" class="widget widget_nav_menu">
                                            <h2 class="widgettitle">About us</h2>
                                            <div class="menu-footermenu-1-container">
                                                <ul id="menu-footermenu-1" class="menu">
                                                    <li class="menu-item"><a href="https://www.yelp.com/biz/goodfellows-coffee-cypress?osq=good+fellows+coffee">Reviews</a></li>
                                                    <li class="menu-item"><a href="https://goodfellows-coffee.square.site/">Order Online</a></li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="barrel-recent-posts-3" class="widget widget_barrel_recent_entries">
                                            <h2 class="widgettitle"></h2>
                                            {/* <ul>
                                                <li class="clearfix">
                                                    <div class="widget-post-details-wrapper">
                                                        <div class="post-title"><a href="News">Ordering Online</a></div>
                                                        <div class="post-date">Coming Soon</div>
                                                    </div>
                                                </li>
                                                <li class="clearfix">
                                                    <div class="widget-post-details-wrapper">
                                                        <div class="post-title"><a href="News">Speciality Drinks</a></div>
                                                        <div class="post-date">Every Season</div>
                                                    </div>
                                                </li>
                                                <li class="clearfix">
                                                    <div class="widget-post-details-wrapper">
                                                        <div class="post-title"><a href="News">Monthly Roasters</a></div>
                                                        <div class="post-date">Monthly Rotation</div>
                                                    </div>
                                                </li>

                                            </ul> */}
                                        </li>
                                        <li id="custom_html-2" class="widget_text widget widget_custom_html">
                                            <h2 class="widgettitle">Information</h2>
                                            <div class="textwidget custom-html-widget">
                                                <p> <i class="fa fa-map-pin"></i>4105 Ball Rd, Cypress, CA 90630</p>
                                                <p> <i class="fa fa-phone"></i>(562) 280-5587<br />
                                                <br />
                                                        For more information <br />
                                                    <span class="text-color-theme">
                                                        <a href="mailto:info@goodfellowscoffee.com" class="__cf_email__">info@goodfellowscoffee.com</a>
                                                    </span>
                                                </p>
                                                <p><a href="/ContactUs">Contact Us</a></p>
                                                <div class="widget_barrel_social_icons shortcode_barrel_social_icons">
                                                    <div class="social-icons-wrapper">
                                                        <ul>
                                                            <li>
                                                                <a href="https://www.instagram.com/goodfellows.coffee/" target="_blank" rel="noreferrer" class="a-instagram"><i class="fa fa-instagram"></i></a>
                                                            </li>
                                                            <li>
                                                                <a href="https://www.yelp.com/biz/goodfellows-coffee-cypress?osq=good+fellows+coffee" rel="noreferrer" target="_blank" class="a-yelp"><i class="fa fa-yelp"></i></a>
                                                            </li>
                                                            <li>
                                                                <a href="https://www.facebook.com/goodfellows.coffee/" target="_blank" rel="noreferrer" class="a-facebook"><i class="fa fa-facebook"></i></a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </>
        )
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Footer)