import React, { Component } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
    body: {
    }
})

class Home extends Component {

    render() {
        const { classes } = this.props
        return (
            <>
                <div className={classes.body}>

                    <div class="content-block stick-to-footer">
                        <div class="page-container container">
                            <div class="row">
                                <div class="col-md-12 entry-content">
                                    <article>
                                        <div data-vc-full-width="true" data-vc-full-width-init="false" data-vc-stretch-content="true" class="vc_row wpb_row vc_row-fluid vc_row-no-padding">
                                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                                <div class="vc_column-inner">
                                                    <div class="wpb_wrapper">
                                                        <div class="wpb_revslider_element wpb_content_element">
                                                            <div id="rev_slider_2_1_wrapper" class="rev_slider_wrapper fullscreen-container" data-source="gallery" style={{ background: "transparent", padding: "0px" }}>
                                                                {/* <!-- START REVOLUTION SLIDER 5.4.5.1 fullscreen mode --> */}
                                                                <div id="rev_slider_2_1" class="rev_slider fullscreenbanner" style={{ display: "none" }} data-version="5.4.5.1">
                                                                    <ul>
                                                                        {/* <!-- SLIDE  --> */}
                                                                        <li
                                                                            data-index="rs-7"
                                                                            data-transition="slidingoverlayright"
                                                                            data-slotamount="default"
                                                                            data-hideafterloop="0"
                                                                            data-hideslideonmobile="off"
                                                                            data-easein="default"
                                                                            data-easeout="default"
                                                                            data-masterspeed="default"
                                                                            data-thumb="upload/mussels-blacktable-100x50.jpg"
                                                                            data-rotate="0"
                                                                            data-saveperformance="off"
                                                                            data-title="Welcome"
                                                                            data-param1=""
                                                                            data-param2=""
                                                                            data-param3=""
                                                                            data-param4=""
                                                                            data-param5=""
                                                                            data-param6=""
                                                                            data-param7=""
                                                                            data-param8=""
                                                                            data-param9=""
                                                                            data-param10=""
                                                                            data-description=""
                                                                        >
                                                                            {/* <!-- MAIN IMAGE --> */}
                                                                            <img
                                                                                src="upload/Front_Coffee_Shop.JPG"
                                                                                alt=""
                                                                                title="coffee_beans"
                                                                                width="1600"
                                                                                height="1067"
                                                                                data-bgposition="center center"
                                                                                data-bgfit="cover"
                                                                                data-bgrepeat="no-repeat"
                                                                                class="rev-slidebg"
                                                                                data-no-retina
                                                                            />
                                                                            {/* <!-- LAYERS --> */}

                                                                            {/* <!-- LAYER NR. 1 --> */}
                                                                            <div
                                                                                class="tp-caption tp-shape tp-shapewrapper tp-resizeme"
                                                                                id="slide-7-layer-8"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['0','0','0','0']"
                                                                                data-width="100%"
                                                                                data-height="full"
                                                                                data-whitespace="nowrap"
                                                                                data-type="shape"
                                                                                data-basealign="slide"
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":20,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                                                data-textAlign="['inherit','inherit','inherit','inherit']"
                                                                                data-paddingtop="[0,0,0,0]"
                                                                                data-paddingright="[0,0,0,0]"
                                                                                data-paddingbottom="[0,0,0,0]"
                                                                                data-paddingleft="[0,0,0,0]"
                                                                                style={{ zIndex: 5, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                                                                            ></div>

                                                                            {/* <!-- LAYER NR. 2 --> */}
                                                                            <h1
                                                                                class="tp-caption tp-resizeme"
                                                                                id="slide-7-layer-1"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['0','0','0','-57']"
                                                                                data-fontsize="['60','60','60','45']"
                                                                                data-lineheight="['65','65','65','48']"
                                                                                data-letterspacing="['-2','-2','-2','']"
                                                                                data-width="['780','780','780','436']"
                                                                                data-height="none"
                                                                                data-whitespace="normal"
                                                                                data-type="text"
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":20,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                                                                                data-textAlign="['center','center','center','center']"
                                                                                data-paddingtop="[0,0,0,0]"
                                                                                data-paddingright="[0,0,0,0]"
                                                                                data-paddingbottom="[0,0,0,0]"
                                                                                data-paddingleft="[0,0,0,0]"
                                                                                style={{
                                                                                    zIndex: 6,
                                                                                    minWidth: "780px",
                                                                                    maxWidth: "780px",
                                                                                    whiteSpace: "normal",
                                                                                    fontSize: "60px",
                                                                                    lineHeight: "65px",
                                                                                    fontWeight: 400,
                                                                                    color: "rgba(255, 255, 255, 1)",
                                                                                    letterSpacing: "-2px",
                                                                                    fontFamily: "Sorts Mill Goudy"
                                                                                }}
                                                                            >
                                                                                A taste of fresh ingredients & speciality drinks
                                                                            </h1>

                                                                            {/* <!-- LAYER NR. 3 --> */}
                                                                            <div
                                                                                class="tp-caption tp-resizeme"
                                                                                id="slide-7-layer-3"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['92','92','92','50']"
                                                                                data-lineheight="['27','27','27','35']"
                                                                                data-color="['rgb(155,155,155)','rgb(155,155,155)','rgb(155,155,155)','rgb(183,183,183)']"
                                                                                data-letterspacing="['3','3','3','-']"
                                                                                data-width="['none','none','none','410']"
                                                                                data-height="none"
                                                                                data-whitespace="['nowrap','nowrap','nowrap','normal']"
                                                                                data-type="text"
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":260,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                                                                                data-textAlign="['center','center','center','center']"
                                                                                data-paddingtop="[0,0,0,0]"
                                                                                data-paddingright="[0,0,0,0]"
                                                                                data-paddingbottom="[0,0,0,0]"
                                                                                data-paddingleft="[0,0,0,0]"
                                                                                style={{ zIndex: 7, whiteSpace: "nowrap", fontSize: "16px", lineHeight: "27px", fontWeight: 400, color: "#9b9b9b", letterSpacing: "3px", fontFamily: "Cabin" }}
                                                                            >
                                                                                WE ARE READY
                                                                            </div>

                                                                            {/* <!-- LAYER NR. 4 --> */}
                                                                            <a
                                                                                class="tp-caption rev-btn"
                                                                                href="https://goodfellows-coffee.square.site"
                                                                                target="_self"
                                                                                id="slide-7-layer-14"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['184','184','184','145']"
                                                                                data-color="['rgb(255,255,255)','rgb(255,255,255)','rgb(255,255,255)','rgba(255,255,255,1)']"
                                                                                data-width="none"
                                                                                data-height="none"
                                                                                data-whitespace="nowrap"
                                                                                data-type="button"
                                                                                data-actions=""
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":20,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"},{"frame":"hover","speed":"500","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(25,18,13);bs:solid;bw:0 0 0 0;"}]'
                                                                                data-textAlign="['inherit','inherit','inherit','inherit']"
                                                                                data-paddingtop="[20,20,20,20]"
                                                                                data-paddingright="[50,50,50,50]"
                                                                                data-paddingbottom="[20,20,20,20]"
                                                                                data-paddingleft="[50,50,50,50]"
                                                                                style={{
                                                                                    zIndex: 8,
                                                                                    whiteSpace: "nowrap",
                                                                                    fontSize: "18px",
                                                                                    lineHeight: "17px",
                                                                                    fontWeight: 400,
                                                                                    color: "#ffffff",
                                                                                    fontFamily: "Cabin",
                                                                                    backgroundColor: "rgb(198, 159, 124)",
                                                                                    borderColor: "rgba(0, 0, 0, 1)",
                                                                                    borderRadius: "30px 30px 30px 30px",
                                                                                    outline: "none",
                                                                                    boxShadow: "none",
                                                                                    boxSizing: "border-box",
                                                                                    "-moz-box-sizing": "border-box",
                                                                                    "-webkit-box-sizing": "border-box",
                                                                                    cursor: "pointer",
                                                                                    textDecoration: "none",
                                                                                }}
                                                                            >
                                                                                Order Online
                                                                            </a>

                                                                            {/* <!-- LAYER NR. 5 --> */}
                                                                            <h1
                                                                                class="tp-caption tp-resizeme"
                                                                                id="slide-7-layer-16"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['-130','-130','-130','-188']"
                                                                                data-fontsize="['120','120','120','60']"
                                                                                data-lineheight="['65','65','65','50']"
                                                                                data-width="['507','507','507','436']"
                                                                                data-height="['82','82','82','none']"
                                                                                data-whitespace="normal"
                                                                                data-type="text"
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":20,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                                                                                data-textAlign="['center','center','center','center']"
                                                                                data-paddingtop="[0,0,0,0]"
                                                                                data-paddingright="[0,0,0,0]"
                                                                                data-paddingbottom="[0,0,0,0]"
                                                                                data-paddingleft="[0,0,0,0]"
                                                                                style={{
                                                                                    zIndex: 9,
                                                                                    minWidth: "507px",
                                                                                    whiteSpace: "normal",
                                                                                    fontSize: "120px",
                                                                                    lineHeight: "65px",
                                                                                    fontWeight: 400,
                                                                                    color: "white",
                                                                                    fontFamily: "Alex Brush",
                                                                                }}
                                                                            >
                                                                                Coffee Shop
                                                                            </h1>
                                                                        </li>
                                                                        {/* <!-- SLIDE  --> */}
                                                                        <li
                                                                            data-index="rs-9"
                                                                            data-transition="slidingoverlayhorizontal"
                                                                            data-slotamount="default"
                                                                            data-hideafterloop="0"
                                                                            data-hideslideonmobile="off"
                                                                            data-easein="default"
                                                                            data-easeout="default"
                                                                            data-masterspeed="default"
                                                                            data-thumb="upload/barmen-coctail-100x50.jpg"
                                                                            data-rotate="0"
                                                                            data-saveperformance="off"
                                                                            data-title="Menu"
                                                                            data-param1=""
                                                                            data-param2=""
                                                                            data-param3=""
                                                                            data-param4=""
                                                                            data-param5=""
                                                                            data-param6=""
                                                                            data-param7=""
                                                                            data-param8=""
                                                                            data-param9=""
                                                                            data-param10=""
                                                                            data-description=""
                                                                        >
                                                                            {/* <!-- MAIN IMAGE --> */}
                                                                            <img
                                                                                src="upload/Side_Coffee_Shop.JPG"
                                                                                alt=""
                                                                                title="barmen-coctail"
                                                                                width="1600"
                                                                                height="2395"
                                                                                data-bgposition="center center"
                                                                                data-bgfit="cover"
                                                                                data-bgrepeat="no-repeat"
                                                                                class="rev-slidebg"
                                                                                data-no-retina
                                                                            />
                                                                            {/* <!-- LAYERS --> */}

                                                                            {/* <!-- LAYER NR. 6 --> */}
                                                                            <div
                                                                                class="tp-caption tp-shape tp-shapewrapper tp-resizeme"
                                                                                id="slide-9-layer-8"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['0','0','0','0']"
                                                                                data-width="100%"
                                                                                data-height="full"
                                                                                data-whitespace="nowrap"
                                                                                data-type="shape"
                                                                                data-basealign="slide"
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":20,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                                                data-textAlign="['inherit','inherit','inherit','inherit']"
                                                                                data-paddingtop="[0,0,0,0]"
                                                                                data-paddingright="[0,0,0,0]"
                                                                                data-paddingbottom="[0,0,0,0]"
                                                                                data-paddingleft="[0,0,0,0]"
                                                                                style={{ zIndex: 5, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                                                                            ></div>

                                                                            {/* <!-- LAYER NR. 7 --> */}
                                                                            <h1
                                                                                class="tp-caption tp-resizeme"
                                                                                id="slide-9-layer-1"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['0','0','0','-57']"
                                                                                data-fontsize="['60','60','60','45']"
                                                                                data-lineheight="['65','65','65','50']"
                                                                                data-letterspacing="['-2','-2','-2','']"
                                                                                data-width="['628','628','628','436']"
                                                                                data-height="['132','132','132','none']"
                                                                                data-whitespace="normal"
                                                                                data-type="text"
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":20,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                                                                                data-textAlign="['center','center','center','center']"
                                                                                data-paddingtop="[0,0,0,0]"
                                                                                data-paddingright="[0,0,0,0]"
                                                                                data-paddingbottom="[0,0,0,0]"
                                                                                data-paddingleft="[0,0,0,0]"
                                                                                style={{
                                                                                    zIndex: 6,
                                                                                    minWidth: "628px",
                                                                                    whiteSpace: "normal",
                                                                                    fontSize: "60px",
                                                                                    lineHeight: "65px",
                                                                                    fontWeight: 400,
                                                                                    color: "rgba(255, 255, 255, 1)",
                                                                                    letterSpacing: "-2px",
                                                                                    fontFamily: "Sorts Mill Goudy",
                                                                                }}
                                                                            >
                                                                                Step into our Coffee Shop and feel at Home
                                                                            </h1>

                                                                            {/* <!-- LAYER NR. 8 --> */}
                                                                            <div
                                                                                class="tp-caption tp-resizeme"
                                                                                id="slide-9-layer-3"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['92','92','92','30']"
                                                                                data-lineheight="['27','27','27','35']"
                                                                                data-color="['rgb(155,155,155)','rgb(155,155,155)','rgb(155,155,155)','rgb(188,188,188)']"
                                                                                data-letterspacing="['3','3','3','-']"
                                                                                data-width="['none','none','none','410']"
                                                                                data-height="none"
                                                                                data-whitespace="['nowrap','nowrap','nowrap','normal']"
                                                                                data-type="text"
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":260,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                                                                                data-textAlign="['center','center','center','center']"
                                                                                data-paddingtop="[0,0,0,0]"
                                                                                data-paddingright="[0,0,0,0]"
                                                                                data-paddingbottom="[0,0,0,0]"
                                                                                data-paddingleft="[0,0,0,0]"
                                                                                style={{
                                                                                    zIndex: 7,
                                                                                    whiteSpace: "nowrap",
                                                                                    fontSize: "16px",
                                                                                    lineHeight: "27px",
                                                                                    fontWeight: 400,
                                                                                    color: "#9b9b9b",
                                                                                    letterSpacing: "3px",
                                                                                    fontFamily: "Cabin"
                                                                                }}
                                                                            >
                                                                                Home Sweet Home
                                                                            </div>

                                                                            {/* <!-- LAYER NR. 9 --> */}
                                                                            <a
                                                                                class="tp-caption rev-btn"
                                                                                href="https://www.instagram.com/goodfellows.coffee/"
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                id="slide-9-layer-14"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['184','184','184','135']"
                                                                                data-color="['rgb(255,255,255)','rgb(255,255,255)','rgb(255,255,255)','rgba(255,255,255,1)']"
                                                                                data-width="none"
                                                                                data-height="none"
                                                                                data-whitespace="nowrap"
                                                                                data-type="button"
                                                                                data-actions=""
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":20,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"},{"frame":"hover","speed":"500","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(25,18,13);bs:solid;bw:0 0 0 0;"}]'
                                                                                data-textAlign="['inherit','inherit','inherit','inherit']"
                                                                                data-paddingtop="[20,20,20,20]"
                                                                                data-paddingright="[50,50,50,50]"
                                                                                data-paddingbottom="[20,20,20,20]"
                                                                                data-paddingleft="[50,50,50,50]"
                                                                                style={{
                                                                                    zIndex: 8,
                                                                                    whiteSpace: "nowrap",
                                                                                    fontSize: "18px",
                                                                                    lineHeight: "17px",
                                                                                    fontWeight: 400,
                                                                                    color: "#ffffff",
                                                                                    fontFamily: "Cabin",
                                                                                    backgroundColor: "rgb(198, 159, 124)",
                                                                                    borderColor: "rgba(0, 0, 0, 1)",
                                                                                    borderRadius: "30px 30px 30px 30px",
                                                                                    outline: "none",
                                                                                    boxShadow: "none",
                                                                                    boxSizing: "border-box",
                                                                                    "-moz-box-sizing": "border-box",
                                                                                    "-webkit-box-sizing": "border-box",
                                                                                    cursor: "pointer",
                                                                                    textDecoration: "none"
                                                                                }}
                                                                            >
                                                                                Instagram
                                                                            </a>

                                                                            {/* <!-- LAYER NR. 10 --> */}
                                                                            <h1
                                                                                class="tp-caption tp-resizeme"
                                                                                id="slide-9-layer-16"
                                                                                data-x="['center','center','center','center']"
                                                                                data-hoffset="['0','0','0','0']"
                                                                                data-y="['middle','middle','middle','middle']"
                                                                                data-voffset="['-130','-130','-130','-161']"
                                                                                data-fontsize="['120','120','120','60']"
                                                                                data-lineheight="['65','65','65','50']"
                                                                                data-width="['none','none','none','436']"
                                                                                data-height="['81','81','81','none']"
                                                                                data-whitespace="normal"
                                                                                data-type="text"
                                                                                data-responsive_offset="on"
                                                                                data-frames='[{"delay":20,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                                                                                data-textAlign="['center','center','center','center']"
                                                                                data-paddingtop="[0,0,0,0]"
                                                                                data-paddingright="[0,0,0,0]"
                                                                                data-paddingbottom="[0,0,0,0]"
                                                                                data-paddingleft="[0,0,0,0]"
                                                                                style={{
                                                                                    zIndex: 9,
                                                                                    maxWidth: "81px",
                                                                                    whiteSpace: "normal",
                                                                                    fontSize: "120px",
                                                                                    lineHeight: "65px",
                                                                                    fontWeight: 400,
                                                                                    color: "white",
                                                                                    fontFamily: "Alex Brush"
                                                                                }}
                                                                            >
                                                                                Handcrafted
                                                                            </h1>
                                                                        </li>
                                                                    </ul>
                                                                    <div class="tp-bannertimer tp-bottom" style={{ visibility: "hidden !important" }}></div>
                                                                </div>

                                                            </div>
                                                            {/* <!-- END REVOLUTION SLIDER --> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row-full-width vc_clearfix"></div>
                                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1496400967126">
                                            <div class="wpb_column vc_column_container vc_col-sm-6">
                                                <div class="vc_column-inner vc_custom_1501610531272">
                                                    <div class="wpb_wrapper">
                                                        <div class="mgt-promo-block-container wpb_content_element wpb_animate_when_almost_visible wpb_fadeInUp fadeInUp">
                                                            <div class="mgt-promo-block-wrapper mgt-promo-block-hover">
                                                                <div
                                                                    class="mgt-promo-block animated white-text cover-image darken mgt-promo-block-66293803"
                                                                    data-style="background-color: #ffffff;background-image: url(upload/Coffee_Heart.png);background-repeat: no-repeat;height: 420px;"
                                                                >
                                                                    <div class="mgt-promo-block-content va-middle">
                                                                        <div class="mgt-promo-block-content-inside vc_custom_1502111254458">
                                                                            <h5 style={{ textAlign: "center" }}> What's New?</h5>
                                                                            <h2 style={{ textAlign: "center" }}>
                                                                                Speciality Drinks
                                                                            </h2>
                                                                            <div
                                                                                class="mgt-button-wrapper mgt-button-wrapper-align-center mgt-button-wrapper-display-newline mgt-button-top-margin-true mgt-button-right-margin-false mgt-button-round-edges-full"
                                                                            >
                                                                                <a
                                                                                    class="btn hvr-grow mgt-button-icon- mgt-button mgt-style-solid mgt-size-normal mgt-align-center mgt-display-newline mgt-text-size-normal mgt-button-icon-separator- mgt-button-icon-position-left text-font-weight-default mgt-text-transform-none"
                                                                                    href="https://goodfellows-coffee.square.site"
                                                                                >
                                                                                    Order Online<i class="entypo-icon entypo-icon-right-open-mini"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="wpb_column vc_column_container vc_col-sm-6">
                                                <div class="vc_column-inner vc_custom_1501610544677">
                                                    <div class="wpb_wrapper">
                                                        <div class="mgt-promo-block-container wpb_content_element wpb_animate_when_almost_visible wpb_fadeInRight fadeInRight">
                                                            <div class="mgt-promo-block-wrapper mgt-promo-block-hover">
                                                                <div
                                                                    class="mgt-promo-block animated white-text cover-image darken mgt-promo-block-65740325"
                                                                    data-style="background-color: #ffffff;background-image: url(upload/Pastry_Case.png);background-repeat: no-repeat;height: 420px;"
                                                                >
                                                                    <div class="mgt-promo-block-content va-middle">
                                                                        <div class="mgt-promo-block-content-inside vc_custom_1502119337451">
                                                                            <h5 style={{ textAlign: "center" }}>About Us</h5>
                                                                            <h2 style={{ textAlign: "center" }}>
                                                                                Learn what makes <br />us the best
                                                                            </h2>
                                                                            <div
                                                                                class="mgt-button-wrapper mgt-button-wrapper-align-center mgt-button-wrapper-display-newline mgt-button-top-margin-true mgt-button-right-margin-false mgt-button-round-edges-full"
                                                                            >
                                                                                <a
                                                                                    class="btn hvr-grow mgt-button-icon- mgt-button mgt-style-solid mgt-size-normal mgt-align-center mgt-display-newline mgt-text-size-normal mgt-button-icon-separator- mgt-button-icon-position-left text-font-weight-default mgt-text-transform-none"
                                                                                    href="https://www.yelp.com/biz/goodfellows-coffee-cypress?osq=good+fellows+coffee"
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Read More<i class="entypo-icon entypo-icon-right-open-mini"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div data-vc-full-width="true" data-vc-full-width-init="false" class="vc_row wpb_row vc_row-fluid vc_custom_1501762265855 vc_row-has-fill">
                                            <div class="wpb_column vc_column_container vc_col-sm-5">
                                                <div class="vc_column-inner vc_custom_1502119248785">
                                                    <div class="wpb_wrapper">
                                                        <div
                                                            style={{ marginTop: "-15px" }}
                                                            class="mgt-header-block clearfix text-left text-black wpb_animate_when_almost_visible wpb_fadeInDown fadeInDown wpb_content_element mgt-header-block-style-2 mgt-header-block-fontsize-medium mgt-header-texttransform-none mgt-header-block-2224038"
                                                        >
                                                            <p class="mgt-header-block-subtitle">Monthly Rotation </p>
                                                            <h2 class="mgt-header-block-title text-font-weight-default">Dark Horse Coffee Roasters</h2>
                                                            <div class="mgt-header-line mgt-header-line-margin-large"></div>
                                                        </div>
                                                        <div class="wpb_text_column wpb_content_element wpb_animate_when_almost_visible wpb_fadeInLeft fadeInLeft text-size-medium">
                                                            <div class="wpb_wrapper">
                                                                <p>
                                                                    <span style={{ color: "#999999" }}>
                                                                        RELATIONSHIPS. That's what coffee is all about. From the farm-level to our cafes, customers and barists...
                                                                        all the way to the cup of coffee you get in the morning. Dark Horse takes pride in sourcing direct-trade coffees and developing
                                                                        extremely close relationships with our smallholder farmers all over the world. <br /><br />
                                                                    </span>

                                                                    <span style={{ color: "#999999", fontSize: "10px" }}>If you want to learn more about this monthly roasters. Click the link below.</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div

                                                            class="mgt-button-wrapper mgt-button-wrapper-align-left mgt-button-wrapper-display-inline mgt-button-top-margin-false mgt-button-right-margin-false mgt-button-round-edges-full wpb_animate_when_almost_visible wpb_fadeInUpBig fadeInUpBig"
                                                        >
                                                            <a
                                                                class="btn hvr-push mgt-button-icon- mgt-button mgt-style-solid mgt-size-normal mgt-align-left mgt-display-inline mgt-text-size-normal mgt-button-icon-separator- mgt-button-icon-position-left text-font-weight-default mgt-text-transform-none"
                                                                href="https://www.darkhorsecoffeeroasters.com/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Monthly Roasters
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="wpb_column vc_column_container vc_col-sm-5">
                                                <div class="vc_column-inner">
                                                    <div class="wpb_wrapper">
                                                        <div class="mgt-promo-block-container wpb_content_element wpb_animate_when_almost_visible wpb_fadeInRight fadeInRight">
                                                            <div class="mgt-promo-block-wrapper mgt-promo-block-hover">
                                                                <div
                                                                    class="mgt-promo-block animated white-text cover-image no-darken mgt-promo-block-69094683"
                                                                    data-style="background-color: #ffffff;background-image: url(upload/Monthly_Rotation_Coffee.JPG);background-repeat: no-repeat;height: 495px;"
                                                                >
                                                                    <div class="mgt-promo-block-content va-middle">
                                                                        <div class="mgt-promo-block-content-inside vc_custom_1502111459689 mgt-promo-block-content-inside-show-on-hover">
                                                                            <h2 style={{ textAlign: "center" }}>Let’s build something amazing. We’re ready when you are.</h2>
                                                                            <p style={{ textAlign: "center" }}>We only bring in the best coffee to create our amazing refreshments.</p>
                                                                            <div
                                                                                class="mgt-button-wrapper mgt-button-wrapper-align-center mgt-button-wrapper-display-newline mgt-button-top-margin-true mgt-button-right-margin-false mgt-button-round-edges-full"
                                                                            >
                                                                                <a
                                                                                    class="btn hvr-push mgt-button-icon-true mgt-button mgt-style-bordered mgt-size-normal mgt-align-center mgt-display-newline mgt-text-size-normal mgt-button-icon-separator- mgt-button-icon-position-right text-font-weight-default mgt-text-transform-none"
                                                                                    href="https://goodfellows-coffee.square.site/"
                                                                                >
                                                                                    Order Now
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="wpb_column vc_column_container vc_col-sm-2">
                                                <div class="vc_column-inner">
                                                    <div class="wpb_wrapper">
                                                        <div class="mgt-promo-block-container wpb_content_element">
                                                            <div class="mgt-promo-block-wrapper mgt-promo-block-shadow mgt-promo-block-hover">
                                                                <div
                                                                    class="mgt-promo-block black-text cover-image no-darken mgt-promo-block-59326650"
                                                                    data-style="background-color: #f5f5f5;background-image: url(upload/monthly_rotation_2.jpg);background-repeat: no-repeat;height: 145px;"
                                                                >
                                                                    <div class="mgt-promo-block-content va-middle"><div class="mgt-promo-block-content-inside"></div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="vc_empty_space" style={{ height: "30px" }}><span class="vc_empty_space_inner"></span></div>
                                                        <div class="mgt-promo-block-container wpb_content_element">
                                                            <div class="mgt-promo-block-wrapper mgt-promo-block-shadow mgt-promo-block-hover">
                                                                <div
                                                                    class="mgt-promo-block black-text cover-image no-darken mgt-promo-block-11490570"
                                                                    data-style="background-color: #f5f5f5;background-image: url(upload/monthly_rotation_2.jpg);background-repeat: no-repeat;height: 145px;"
                                                                >
                                                                    <div class="mgt-promo-block-content va-middle"><div class="mgt-promo-block-content-inside"></div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="vc_empty_space" style={{ height: "30px" }}><span class="vc_empty_space_inner"></span></div>
                                                        <div class="mgt-promo-block-container wpb_content_element">
                                                            <div class="mgt-promo-block-wrapper mgt-promo-block-shadow mgt-promo-block-hover">
                                                                <div
                                                                    class="mgt-promo-block black-text cover-image no-darken mgt-promo-block-73119794"
                                                                    data-style="background-color: #f5f5f5;background-image: url(upload/monthly_rotation_2.jpg);background-repeat: no-repeat;height: 145px;"
                                                                >
                                                                    <div class="mgt-promo-block-content va-middle"><div class="mgt-promo-block-content-inside"></div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="vc_row-full-width vc_clearfix"></div>
                                        <div
                                            data-vc-full-width="true"
                                            data-vc-full-width-init="false"
                                            data-vc-stretch-content="true"
                                            data-vc-parallax="1.5"
                                            data-vc-parallax-image="upload/home_burrito.jpg"
                                            class="vc_row wpb_row vc_row-fluid vc_custom_1501853508813 vc_row-has-fill vc_row-no-padding vc_general vc_parallax vc_parallax-content-moving"
                                        >
                                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                                <div class="vc_column-inner">
                                                    <div class="wpb_wrapper">
                                                        <h2 style={{
                                                            fontSize: "55px",
                                                            color: "#c69f7c",
                                                            lineHeight: "58px",
                                                            textAlign: "center",
                                                            fontFamily: "Alex Brush",
                                                            fontWeight: 400,
                                                            fontStyle: "normal"
                                                        }}
                                                            class="vc_custom_heading">
                                                            When it satisfies your tastebuds
                                                        </h2>
                                                        <h2 style={{
                                                            fontSize: "65px",
                                                            color: "#ffffff",
                                                            lineHeight: "68px",
                                                            textAlign: "center"
                                                        }}
                                                            class="vc_custom_heading">Breakfast Burritos</h2>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row-full-width vc_clearfix"></div>
                                        <div style={{ marginTop: "-100px" }} data-vc-full-width="true" data-vc-full-width-init="false" class="vc_row wpb_row vc_row-fluid vc_custom_1501767193871">
                                            <div class="vc_row wpb_row vc_row-fluid">
                                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                                    <div class="vc_column-inner vc_custom_1501868700286">
                                                        <div class="wpb_wrapper">
                                                            <div
                                                                class="mgt-header-block clearfix text-center text-black wpb_animate_when_almost_visible wpb_fadeInDown fadeInDown wpb_content_element mgt-header-block-style-2 mgt-header-block-fontsize-medium mgt-header-texttransform-none mgt-header-block-53132001"
                                                            >
                                                                <p class="mgt-header-block-subtitle">Drink &amp; Eat with us</p>
                                                                <h2 class="mgt-header-block-title text-font-weight-default">GoodFellows Coffee</h2>
                                                                <div class="mgt-header-line mgt-header-line-margin-large"></div>
                                                            </div>
                                                            <div class="wpb_text_column wpb_content_element wpb_animate_when_almost_visible wpb_fadeInUp fadeInUp text-size-medium">
                                                                <div class="wpb_wrapper">
                                                                    <p style={{ textAlign: "center" }}>
                                                                        <span style={{ color: "#999999" }}>
                                                                            At Goodfellows Coffee, we believe in supporting the local coffee community. Every month, we feature a different coffee roaster from Orange and LA County for our espresso beans.
                                                                        </span>
                                                                    </p>
                                                                    <p style={{ textAlign: "center" }}>
                                                                        <span style={{ color: "#999999" }}>
                                                                            For our drip coffee, Goodfellows Coffee proudly serves Peerless Coffee. Peerless Coffee is based in Oakland, CA and was one of the very first craft coffee roasters founded in 1924, Peerless Coffee is rich in tradition and has
                                                                            a history of quality and sustainabliity.
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div class="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_fadeInUp fadeInUp">
                                                                <figure class="wpb_wrapper vc_figure">
                                                                    <div class="vc_single_image-wrapper vc_box_border_grey">
                                                                        <img width="96" height="41" src="upload/branch.png" class="vc_single_image-img attachment-full" alt="" />
                                                                    </div>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginBottom: "-0px" }} class="vc_row-full-width vc_clearfix"></div>
                                        <div
                                            data-vc-full-width="true"
                                            data-vc-full-width-init="false"
                                            data-vc-stretch-content="true"
                                            data-vc-parallax="1.5"
                                            data-vc-parallax-image="upload/Coffee_Bar.JPG"
                                            class="vc_row wpb_row vc_row-fluid vc_custom_1501853930727 vc_row-has-fill vc_row-no-padding vc_general vc_parallax vc_parallax-content-moving"
                                        >
                                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                                <div class="vc_column-inner">
                                                    <div class="wpb_wrapper">
                                                        <h2 style={{
                                                            fontSize: "55px",
                                                            color: "white",
                                                            lineHeight: "58px",
                                                            textAlign: "center",
                                                            fontFamily: "Alex Brush",
                                                            fontWeight: 400,
                                                            fontStyle: "normal"
                                                        }}
                                                            class="vc_custom_heading">
                                                            Start your experience
                                                        </h2>
                                                        <h2 style={{
                                                            fontSize: "65px",
                                                            color: "#ffffff",
                                                            lineHeight: "68px",
                                                            textAlign: "center"
                                                        }}
                                                            class="vc_custom_heading">Always happy to see you</h2>
                                                        <div
                                                            class="mgt-button-wrapper mgt-button-wrapper-align-center mgt-button-wrapper-display-newline mgt-button-top-margin-true mgt-button-right-margin-false mgt-button-round-edges-full wpb_animate_when_almost_visible wpb_fadeInUp fadeInUp"
                                                        >
                                                            <a
                                                                class="btn hvr-push mgt-button-icon- mgt-button mgt-style-solid mgt-size-large mgt-align-center mgt-display-newline mgt-text-size-normal mgt-button-icon-separator- mgt-button-icon-position-left text-font-weight-default mgt-text-transform-none"
                                                                href="https://www.google.com/maps/place/4105+Ball+Rd,+Cypress,+CA+90630/@33.8184669,-118.0629402,17z/data=!4m5!3m4!1s0x80dd2e868bee8ca1:0x95577927a46e8828!8m2!3d33.8184669!4d-118.0607515"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Get direction
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row-full-width vc_clearfix"></div>


                                        <div data-vc-full-width="true" data-vc-full-width-init="false" class="vc_row wpb_row vc_row-fluid vc_custom_1501762265855 vc_row-has-fill">

                                            <div class="wpb_column vc_column_container vc_col-sm-8">
                                                <div class="vc_column-inner vc_custom_1501770932758">
                                                    <div class="wpb_wrapper">
                                                        <div class="mgt-portfolio-grid wpb_content_element">
                                                            <div class="portfolio-list portfolio-columns-4 portfolio-grid-layout-4 clearfix" id="portfolio-list-76440791">
                                                                <div class="portfolio-item-block text-left portfolio-item-animation-1 slide-item desert events fish meat" data-item="1" data-name="The Rooster Bacon Burrito">
                                                                    <div class="portfolio-item-block-inside">

                                                                        <div class="portfolio-item-image" data-style="background-image: url(upload/Shop_View_Front_Window_Sign.jpg);"></div>
                                                                        <div class="portfolio-item-bg"></div>

                                                                        <div class="info">
                                                                            <span class="sub-title">Welcome</span>
                                                                            <h4 class="title">The Coziest Coffee Shop</h4>
                                                                            <div class="project-description">Amazing Drinks,Food,Environment</div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="portfolio-item-block text-left portfolio-item-animation-1 slide-item desert events fish meat" data-item="2" data-name="Lavender Honey Latte">
                                                                    <div class="portfolio-item-block-inside">

                                                                        <div class="portfolio-item-image" data-style="background-image: url(upload/Shop_View_Front_Inside_Shop.jpg);"></div>
                                                                        <div class="portfolio-item-bg"></div>

                                                                        <div class="info">
                                                                            <span class="sub-title">Shop View</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="portfolio-item-block text-left portfolio-item-animation-1 slide-item fish" data-item="3" data-name="Mexican Mocha">
                                                                    <div class="portfolio-item-block-inside">

                                                                        <div class="portfolio-item-image" data-style="background-image: url(upload/Shop_View_Pastry_Case_2.jpg);"></div>
                                                                        <div class="portfolio-item-bg"></div>

                                                                        <div class="info">
                                                                            <span class="sub-title">Shop View</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="portfolio-item-block text-left portfolio-item-animation-1 slide-item desert events fish meat" data-item="1" data-name="The Good Morning Bagel">
                                                                    <div class="portfolio-item-block-inside">

                                                                        <div
                                                                            class="portfolio-item-image"
                                                                            data-style="background-image: url(upload/Shop_View_Good_People_Coffee_Times.jpg);"
                                                                        ></div>
                                                                        <div class="portfolio-item-bg"></div>

                                                                        <div class="info">
                                                                            <span class="sub-title">Shop View</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="portfolio-item-block text-left portfolio-item-animation-1 slide-item events" data-item="2" data-name="Mocha">
                                                                    <div class="portfolio-item-block-inside">

                                                                        <div
                                                                            class="portfolio-item-image"
                                                                            data-style="background-image: url(upload/Shop_View_Order_Station.JPG);"
                                                                        ></div>
                                                                        <div class="portfolio-item-bg"></div>

                                                                        <div class="info">
                                                                            <span class="sub-title">Shop View</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="portfolio-item-block text-left portfolio-item-animation-1 slide-item desert events meat" data-item="3" data-name="Matcha Latte">
                                                                    <div class="portfolio-item-block-inside">

                                                                        <div class="portfolio-item-image" data-style="background-image: url(upload/Coffee_Bar.JPG);"></div>
                                                                        <div class="portfolio-item-bg"></div>

                                                                        <div class="info">
                                                                            <span class="sub-title">Shop View</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="wpb_column vc_column_container vc_col-sm-4">
                                                <div class="vc_column-inner vc_custom_1501770941177">
                                                    <div class="wpb_wrapper">
                                                        <div
                                                            class="mgt-header-block clearfix text-left text-black wpb_animate_when_almost_visible wpb_fadeInDown fadeInDown wpb_content_element mgt-header-block-style-2 mgt-header-block-fontsize-medium mgt-header-texttransform-none mgt-header-block-46788011"
                                                        >
                                                            <p class="mgt-header-block-subtitle">Drink/Eat with us</p>
                                                            <h2 class="mgt-header-block-title text-font-weight-default">Our priority is to offer a warm welcome</h2>
                                                            <div class="mgt-header-line mgt-header-line-margin-large"></div>
                                                        </div>
                                                        <div class="wpb_text_column wpb_content_element wpb_animate_when_almost_visible wpb_fadeInRight fadeInRight text-size-medium">
                                                            <div class="wpb_wrapper">
                                                                <p>
                                                                    <span style={{ color: "#999999" }}>
                                                                        This Coffee Shop is a brewery of life – whether it’s a warm vanilla latte or one of our famous bagel sandwichs. We will always make sure that you have an warm welcoming experience.
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                   
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row-full-width vc_clearfix"></div>
                                        <div
                                            data-vc-full-width="true"
                                            data-vc-full-width-init="false"
                                            data-vc-stretch-content="true"
                                            data-vc-parallax="1.5"
                                            data-vc-parallax-image="upload/Good_People_Coffee_Times.JPG"
                                            class="vc_row wpb_row vc_row-fluid vc_custom_1501853930727 vc_row-has-fill vc_row-no-padding vc_general vc_parallax vc_parallax-content-moving"
                                        >
                                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                                <div class="vc_column-inner">
                                                    <div class="wpb_wrapper">
                                                        <h2 style={{
                                                            fontSize: "100px",
                                                            color: "transparent",
                                                            lineHeight: "58px",
                                                            textAlign: "center",
                                                            fontFamily: "Alex Brush",
                                                            fontWeight: 400,
                                                            fontStyle: "normal"
                                                        }}
                                                            class="vc_custom_heading">
                                                            Instagram
                                                        </h2>
                                                        <h2 style={{
                                                            fontSize: "25px",
                                                            color: "transparent",
                                                            lineHeight: "68px",
                                                            textAlign: "center"
                                                        }}
                                                            class="vc_custom_heading">#goodfellowcoffees</h2>
                                                        <div
                                                            class="mgt-button-wrapper mgt-button-wrapper-align-center mgt-button-wrapper-display-newline mgt-button-top-margin-true mgt-button-right-margin-false mgt-button-round-edges-full wpb_animate_when_almost_visible wpb_fadeInUp fadeInUp"
                                                        >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row-full-width vc_clearfix"></div>
                                        <div style={{ paddingTop: "30px" }} class="embedsocial-hashtag" data-ref="47e22cb9f8375152ded38cabe288f9cd95e98124"></div>
                                        <div class="vc_row-full-width vc_clearfix"></div>

                                        <div data-vc-full-width="true" data-vc-full-width-init="false" class="vc_row wpb_row vc_row-fluid vc_custom_1501773456482 vc_row-has-fill">
                                            <div class="wpb_column vc_column_container vc_col-sm-3">
                                                <div class="vc_column-inner vc_custom_1501770932758"><div class="wpb_wrapper"></div></div>
                                            </div>
                                            <div class="wpb_column vc_column_container vc_col-sm-6">
                                                <div class="vc_column-inner">
                                                    <div class="wpb_wrapper">
                                                        <div
                                                            class="mgt-header-block clearfix text-center text-white wpb_animate_when_almost_visible wpb_fadeInDown fadeInDown wpb_content_element mgt-header-block-style-2 mgt-header-block-fontsize-medium mgt-header-texttransform-none mgt-header-block-12522103"
                                                        >
                                                            <p class="mgt-header-block-subtitle">Come in Today</p>
                                                            <h2 class="mgt-header-block-title text-font-weight-default">GoodFellows Coffee</h2>
                                                            <div class="mgt-header-line mgt-header-line-margin-large"></div>
                                                        </div>
                                                        <div class="wpb_text_column wpb_content_element wpb_animate_when_almost_visible wpb_fadeInLeft fadeInLeft text-size-medium">
                                                            <div class="wpb_wrapper">
                                                                <p style={{ textAlign: "center" }}>
                                                                    <span style={{ color: "#999999" }}>
                                                                        Local Speciality Coffee Shop</span>
                                                                </p>
                                                                <p style={{ textAlign: "center" }}>
                                                                    <span style={{ color: "#999999" }}>
                                                                        Where Good Coffee</span>
                                                                </p>
                                                                <p style={{ textAlign: "center" }}>
                                                                    <span style={{ color: "#999999" }}>
                                                                        Meets Good People</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="mgt-button-wrapper mgt-button-wrapper-align-center mgt-button-wrapper-display-newline mgt-button-top-margin-false mgt-button-right-margin-false mgt-button-round-edges-full wpb_animate_when_almost_visible wpb_fadeInUpBig fadeInUpBig"
                                                        >
                                                            <a
                                                                class="btn hvr-push mgt-button-icon- mgt-button mgt-style-bordered mgt-size-large mgt-align-center mgt-display-newline mgt-text-size-normal mgt-button-icon-separator- mgt-button-icon-position-left text-font-weight-default mgt-text-transform-none"
                                                                href="https://www.google.com/maps/place/4105+Ball+Rd,+Cypress,+CA+90630/@33.8184669,-118.0629402,17z/data=!4m5!3m4!1s0x80dd2e868bee8ca1:0x95577927a46e8828!8m2!3d33.8184669!4d-118.0607515"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Find Us
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="wpb_column vc_column_container vc_col-sm-3">
                                                <div class="vc_column-inner"><div class="wpb_wrapper"></div></div>
                                            </div>
                                        </div>
                                        <div class="vc_row-full-width vc_clearfix"></div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Home)